.project-all-wrap {
  margin-bottom: 60px;
}
.project-thumbnail-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 30px 0;
  list-style: none;
}
.title-icon-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
}
.project-title {
  font-size: 1.5rem;
  margin: 0 16px 0 0;
}

.vue-icon {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
  padding: 0 0 0 0;
}
.spring-boot-icon {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
  padding: 0 0 0 0;
}
.redis-icon {
  width: 24px;
  height: 24px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.react-icon {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
  padding: 0 0 0 0;
}
.solidity-icon {
  width: 24px;
  height: 24px;
  margin: 0 12px 0 0;
  padding: 0 0 0 0;
}

.project-detail-wrap {
  display: flex;
  margin: 20px 0;
}
.project-detail-title {
  width: 92px;
}
.project-detail-content {
  width: calc(100% - 92px);
}
.project-detail-link {
  cursor: pointer;
}
.project-detail-link:hover {
  text-decoration: underline;
  color: #3131ff;
}
.project-detail-link:active {
  color: #00aeff;
}
.project-ucc-wraper {
  /* display: flex; */
  align-items: center;
}
.project-ucc-time {
  position: absolute;
  margin: -24px 0 0 260px;
}
.project-ui-all-wrap {
  display: flex;
  flex-flow: row wrap;
}
.project-ui-wrap {
  margin: 5px 30px 30px 0px;
  width: 250px;
}