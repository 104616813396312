.sudal-icon {
  width: 149px;
  height: 149px;
  border-radius: 15px;
  margin-right: 32px;
  background-color: #ffffff;
}
.sudal-icon:hover {
  background-color: #3131ff;
}
.sudal-icon:active {
  background-color: #00aeff;
}