.profile-address-link {
  font-size: 1.2rem;
}
.myname-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.myname {
  font-size: 3rem;
}
.myname-en {
  font-size: 2rem;
  margin-left: 16px;
}