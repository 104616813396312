.nangman-icon {
  width: 149px;
  height: 149px;
  border-radius: 15px;
  margin-right: 32px;
  background-color: #ffffff;
}
.induce-icon {
  position: absolute;
  width: 150px;
  height: 125px;
  margin: -170px 0 0 130px;
}
.nangman-ui-examples {
  width: 250px;
  height: 444px;
}