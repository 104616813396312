.jaws-icon {
  width: 149px;
  height: 149px;
  border-radius: 15px;
  margin-right: 32px;
  background-color: #ffffff;
}
.jaws-ui-examples {
  width: 250px;
  height: 541.6px;
}