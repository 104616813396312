.major-page {
  justify-content: center;
  display: flex;
  font-size: 1.2rem;
  margin: 20px 0 30px;
}

.maple-official {
  margin-right: 20px;
}
.maple-gg {
  margin-right: 20px;
}
.maple-inven {
  margin-right: 20px;
}
.maple-gallery {
  margin-right: 20px;
}

.minor-tip-wrap {
  display: flex;
  margin-bottom: 50px;
}

.minor-wrap {
  width: 50%;
}
.minor-page-title {
  margin-left: 32px;
  font-size: 2rem;
  font-weight: bold;
}
.minor-page {
  margin-left: 48px;
}
.bm-try-title {
  font-size: 1.5rem;
  font-weight: 550;
}
.bm-try {
  margin-left: 12px;
  line-height: 2rem;
}
.member-title {
  font-size: 1.5rem;
  font-weight: 550;
}
.member {
  margin-left: 12px;
}
.maple-nickname {
  display: block;
}

.hisyo-link {
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
}
.hisyo-link:hover {
  color: #ff0000;
}
.tip-page-title {
  margin-left: 0px;
  font-size: 2rem;
  font-weight: bold;
}
.tip-page {
  margin-left: 16px;
}

.maple-page-background {
  position: absolute;
  width: 50%;
  height: auto;
  z-index: -1;
  opacity: 0.2;
  right: 32px;
  top: 100px;
}