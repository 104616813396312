@font-face {
  font-family: noto-sans-regular;
  src: url("../../public/font-assets/NotoSansKR-Regular.otf");
}


@media only screen and (min-width: 830px) {
  /* 데스크탑일 경우 넣을 것들 */
  .grand-title {
    font-size: 4rem;
  }
  .little-title {
    font-size: 2rem;
    margin-right: 16px;
  }
  .little-title-en {
    font-size: 1.6rem;
  }
  .profile-area {
    padding: 50px;
    z-index: 1;
  }
  .profile-group {
    margin-left: calc(50% - (320px + 340px) / 2);
  }
  .profile-picture {
    width: 320px;
    height: 320px;
    margin: 0 40px 0 0;
  }
  .nickname {
    font-size: 3rem;
    margin-bottom: 32px;
  }
  .profile-address {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }
  .designation-area {
    padding: 50px;
    z-index: 1;
  }
  .designation {
    transform: translate(25%, 0);
  }
  .pzgs-designation {
    width: 50%;
    margin-bottom: 20px;
  }
  .ssafy-designation {
    width: 50%;
    margin-bottom: 20px;
  }
  .son-designation {
    width: 50%;
    margin-bottom: 20px;
  }
  .active-area {
    padding: 50px;
    z-index: 1;
  }
  .game-title {
    margin: 0 0 0 16px;
    font-size: 1.2rem;
  }
  .maple {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: #ff9d00;
  }
  .game-nickname {
    margin: 0 10px 0 2px;
    font-size: 1.2rem;
  }
  .mahjongsoul {
    width: 100px;
    height: 100px;
    background-color: #29335a;
    border-radius: 10px;
  }
  .mahjongsoul-rank {
    width: 70px;
  }
}

@media only screen and (max-width: 829px) {
  /* 모바일일 경우 넣을 것들 */
  .grand-title {
    font-size: 2rem;
  }
  .little-title {
    font-size: 1.4rem;
    margin-right: 12px;
  }
  .little-title-en {
    font-size: 1rem;
  }
  .profile-area {
    padding: 25px;
    z-index: 1;
  }
  .profile-group {
    margin-left: calc(50% - (160px + 230px) / 2);
  }
  .profile-picture {
    width: 160px;
    height: 160px;
    margin: 0 20px 0 0;
  }
  .nickname {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }
  .profile-address {
    font-size: 1rem;
  }
  .designation-area {
    padding: 25px;
    z-index: 1;
  }
  .designation {
    transform: translate(15%, 0);
  }
  .pzgs-designation {
    width: 70%;
    margin-bottom: 20px;
  }
  .ssafy-designation {
    width: 70%;
    margin-bottom: 20px;
  }
  .son-designation {
    width: 70%;
    margin-bottom: 20px;
  }
  .active-area {
    padding: 25px;
    z-index: 1;
  }
  .game-title {
    margin: 0 0 0 10px;
    font-size: 1rem;
  }
  .maple {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background-color: #ff9d00;
  }
  .game-nickname {
    margin: 0 10px 0 4px;
    font-size: 1rem;
  }
  .mahjongsoul {
    width: 60px;
    height: 60px;
    background-color: #29335a;
    border-radius: 6px;
  }
  .mahjongsoul-rank {
    width: 50px;
  }
}

/* 공통적으로 넣을 것들 */
body {
  margin: 0;
  padding: 0;
  background: #08002b;
  font-family: noto-sans-regular;
  color: #eeeeee;
}
.grand-title {
  text-align: center;
  font-family: noto-sans-regular;
  font-weight: 900;
  background-image: linear-gradient(45deg, #3131ff, #00aeff);
  -webkit-background-clip: text;
  color: transparent;
}
.little-title-wrap {
  display: flex;
  align-items: center;
}
.little-title {
  font-family: noto-sans-regular;
  font-weight: 700;
  color: #eeeeee;
}
.little-title-en {
  font-family: noto-sans-regular;
  color: #e0e0e0;
}
.profile-group {
  display: flex;
  align-items: center;
}
.profile-picture {
  border-radius: 50%;
  /* border-width: 1px;
  border-style: solid;
  border-color: #eeeeee; */
  background-image: linear-gradient(90deg, red, orange, yellow, green, blue, navy, purple);
}
.nickname {
  font-family: noto-sans-regular;
  font-weight: 900;
  color: #eeeeee;
}
.profile-address {
  font-family: noto-sans-regular;
  color: #eeeeee;
}
a:link {
  text-decoration: none;
  color: #eeeeee;
}
a:visited {
  text-decoration: none;
  color: #eeeeee;
}
a:hover {
  text-decoration: underline;
  color: #3131ff;
}
a:active {
  text-decoration: underline;
  color: #00aeff;
}

.designation {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.pzgs-designation {
  flex-shrink: 1;
  cursor: pointer;
}
.ssafy-designation {
  flex-shrink: 1;
  cursor: pointer;
}
.son-designation {
  flex-shrink: 1;
}

.game-title {
  font-family: noto-sans-regular;
  color: #eeeeee;
}
.game-nickname {
  font-family: noto-sans-regular;
  font-weight: 900;
  color: #eeeeee;
}

.maple-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.maple-game-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.maple-status-wrap {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

.mahjongsoul-wrap {
  display: flex;
  align-items: center;
}
.mahjongsoul-game-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mahjongsoul-rank-wrap {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}
.mahjongsoul-character {
  height: 106px;
}

.bermyu-footer {
  margin: 0 0 50px 50px;
}