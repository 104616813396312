.quest-wrap {
    display: flex;
    border: 1px solid #909090;
}
.quest-left-wrap {
    width: 54px;
    padding: 1em;
    text-align: center;
    border: 1px solid #fff;
}
.quest-right-wrap {
    padding: 1em;
}

.quest-type {
    font-size: 1.2em;
    font-weight: bold;
    color: #fff;
    width: 2em;
    padding: 0.5em;
    border: 1px solid #fff;
}
.quest-number {
    font-size: 2em;
    font-weight: 800;
    color: #fff;
    height: 48px;
    padding: 0.5em;
    border: 1px solid #fff;
}
.quest-title {
    font-size: 1.2em;
    font-weight: bold;
    color: #fff;
    padding: 0.5em;
    border: 1px solid #fff;
}
.quest-content {
    font-size: 1em;
    color: #fff;
    padding: 1em;
    border: 1px solid #fff;
    /*단, 클릭해서 확대할 시에는 이 옵션이 적용되지 않아야 함*/
    height: 48px;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}