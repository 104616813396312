.experience-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.ssafy-logo {
  width: 139px;
  height: 100px;
  padding: 24.5px 5px;
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
}
.ssafy-logo:hover {
  background-color: #3131ff;
}
.ssafy-logo:active {
  background-color: #00aeff;
}
.ssafy-subtitle {
  margin: 0 0 0 32px;
  font-size: 1.2rem;
}
.ssafy-title {
  font-size: 1.5rem;
  margin-bottom: 16px;
}